import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Breadcrumb,
  Layout,
  Button,
  message,
  Table,
  Space,
  Input,
  Modal,
  Select,
  Radio,
  DatePicker,InputNumber
} from "antd";


import { Link, Redirect,useHistory } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import {
  ReloadOutlined,
  ScanOutlined,
  QrcodeOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import moment from "moment";
const _ = require("underscore");
const { RangePicker} = DatePicker
const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Outgoing(props) {
  
  const history = useHistory()
  const [data, setData] = useState({
    po_number: "",
    dn_number: "",
    t_qhnd: "",
    stock_outgoing: "",
    part_number: "",
    part_name: "",
    warehouse: "",
    pcs_kanban: "",
    scan_by: "",
    split_count: "",
    date: moment(new Date()),
    start_date: moment().startOf('month'),
    end_date: moment()
  });
  const [keyword, setKeyword] = useState("");
  const [outgoing, setOutgoing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("label");
  const [state, setState] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "t_item",
    order: "ascend",
  });
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [dataDisabled, setDataDisabled] = useState({
    po_number: false,
    dn_number: true,
    part_number: true,
    stock_outgoing: true,
    qty: true,
    unique_code: true,
  });

  const [qrId, setQrId] = useState("");
  const [part, setPart] = useState({
    part_number: undefined,
    part_name: undefined,
  });
  const [parts, setParts] = useState([]);
  const [partsDetail, setPartsDetail] = useState([]);

  const handleSearch = (value) => {
    setKeyword(value);
    sessionStorage.setItem("keyword", value);
    setPage(1);

    if (value === "") {
      getData();
    }
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const showModal = () => {
    setState({
      visible_outgoing: true,
    });
  };

  const DnRef = useRef(null);
  const QtyRef = useRef(null);

  useEffect(
    () => {
      getData();
      //handleGetParts("");
    },
    [keyword, page, sort],
    [part.part_number]
  );

  const getData = () => {
    setLoading(true);
    const currentDate = moment(data.start_date);
    const endDate = moment(data.end_date);

    Axios.get(`${url}/warehouse-outgoing`, {
      params: {
        keyword: sessionStorage.getItem("keyword")?? keyword,
        start_date : currentDate.format('YYYY-MM-DD'),
        end_date : endDate.format('YYYY-MM-DD'),
        // columns: [
        //   "t_item",
        //   "t_dsca",
        //   "t_nama",
        // ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setOutgoing(res.data.data??[]);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const scanData2 = async (e, name) => {
    e.preventDefault();

    const dataDN = data.dn_number.split(";");

    const DNdelimeter = dataDN.length;

    if (DNdelimeter < 6 && name === "dn_number") {
      message.error("Bad QR Code for DN Number");
    } else {
      Axios.get(`${url}/warehouse-outgoing-scan`, {
        params: {
          po_number: data.po_number,
          dn_number: dataDN[5],
          part_number: dataDN[0],
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.data) {
            if (name === "dn_number") {
              setDataDisabled({
                ...dataDisabled,
                dn_number: true,
                qty: false,
              });

              setData({
                ...data,
                stock_outgoing: dataDN[1],
                part_name: res.data.data.part_name,
                part_number: res.data.data.part_number,
                pcs_kanban: res.data.data.pcs_kanban,
              });

              QtyRef.current.focus();
            } else {
              setDataDisabled({
                ...dataDisabled,
                po_number: true,
                dn_number: false,
              });

              DnRef.current.focus();
            }
          } else {
            message.error("Data not found!");
          }
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error("Backend not yet Started");
          }
        });
    }
  };
  const scanData = async (e, name) => {
    e.preventDefault();
      Axios.get(`${url}/warehouse-outgoing-scan`, {
        params: {
          part_number: e.target.value,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.status) {
              setDataDisabled({
                ...dataDisabled,
                dn_number: true,
                qty: false,
              });

              setData({
                ...data,
                part_name: res.data.data?.description,
                part_number: res.data.data?.part_number,
                pcs_kanban: (res.data.data?.qty) - (res.data.data?.reduce_qty?? 0),
                split_count: res.data.data?.split_count?? 0,
                unique_code: res.data.data?.unique_code
              });

              setQrId(res.data.data.qr_code)
           
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error("Backend not yet Started");
          }
        });
    
  };
  const handleGetParts = async (e,tipe) => {
    Axios.get(`${url}/warehouse-outgoing/list`, {
      params: {
        keyword: e,
        by: tipe
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.data) {
          setParts(res.data.data);
        } else {
          message.error("Data not found!");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const getDataPart = async (partno, desc,invoice) => {
    Axios.get(`${url}/warehouse-outgoing-bypart`, {
      params: {
        part_number: partno,
        no_invoice: invoice,
        by : value,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setPartsDetail(res.data.data);
         console.log(res.data?.data)
          if(partno){
            if(value!="label"){
              setState({
                detail_material: true,
              });
            }

            if(value=="custom"){
              setData({
                ...data,
                part_number: partno,
                part_name: desc,
                split_count : 1
              })
            }
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  //action saat memilih part_number
  const handleSetData = (partnumb) => {
    
      const selectedPart = parts.find((part_no) => part_no.part_number === partnumb);
      setData({
        ...data,
        part_number: selectedPart?.part_number,
        part_name: selectedPart?.description,
        pcs_kanban: value=="custom"? "" :selectedPart?.qty,
        split_count: value=="custom"?0 : selectedPart?.split_count,
        unique_code: value=="custom"? "" :selectedPart?.unique_code
      });
      getDataPart(selectedPart?.part_number, selectedPart?.description, selectedPart?.no_invoice)
      setQrId(value=="manual" && selectedPart?.qr_code)
      setDataDisabled({
        ...dataDisabled,
        qty : false
      })
    
  };

  const saveData2 = () => {
    setLoading(true);

    const dataDN = data.dn_number.split(";");

    const part_number = value === "label" ? dataDN[0] : data.part_number;
    if (data.stock_outgoing % parseInt(data.pcs_kanban) === 0) {
      Axios.post(
        `${url}/warehouse-outgoing`,
        {
          // po_number: data.po_number,
          dn_number: dataDN[5],
          warehouse: data.warehouse,
          scan_by: value,
          //part_number: dataDN[0],
          part_number,
          part_name: data.part_name,
          stock_outgoing: data.stock_outgoing,
          date: moment(data.date).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          message.success(res.data.message);
          handleReset();
          getData();
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error("Backend not yet Started");
          }
        })
        .finally(() => {
          setState({
            ...state,
            visible_outgoing: false,
          });
          setLoading(false);
        });
    } else {
      message.error("Qty is not a multiple of SNP");
      setLoading(false);
    }
  };
  const saveData = () => {
    setLoading(true);
    if (data.unique_code!=""){
      if (data.stock_outgoing <= parseInt(data.pcs_kanban)) {
        let split = false;
        if(data.split_count){ //pertama kali di proses out
          //out masih menyisakan sisa stok
          split = true;
        }else{
          if(parseInt(data.stock_outgoing) != parseInt(data.pcs_kanban)) {
            split = true;
          }
        }
        
        Axios.post(
          `${url}/warehouse-outgoing`,
          {
            qrid :qrId,
            warehouse: data.warehouse,
            scan_by: value,
            part_number:data.part_number,
            part_name: data.part_name,
            stock_outgoing: data.stock_outgoing,
            stock_current: data.pcs_kanban,
            split : split,
            split_count : data.split_count?? 0,
            date: moment(data.date).format("YYYY-MM-DD HH:mm:ss"),
            unique_code: data.unique_code
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
          .then((res) => {
            if(res.data.status){
              //message.success(res.data.message);
              let dataSelected = [res.data.payload];
              history.push('/printLabelMaterial', { state: { dataSelected } })
              handleReset();
            }else{
              message.error(res.data.message);
            }
          
            getData();
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
            } else {
              message.error("Backend not yet Started");
            }
          })
          .finally(() => {
            setState({
              ...state,
              visible_outgoing: false,
            });
            setLoading(false);
          });
      } else {
        message.error("Qty should not be greater than stock!");
      }
    }else {
      message.error("Unique Code cannot be null !");
    }
    setLoading(false);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleCancel = (e) => {
    setState({
      visible_outgoing: false,
    });
    handleReset()
  };
  const handleCancelModalDetail = (e) => {
    if(value=='manual'){
      setData({
        ...data,
        part_name: "",
        part_number: "",
        pcs_kanban: "",
        split_count: "",
        warehouse: data.warehouse?? "",
        unique_code:""
      });
      setQrId("")
    }
    
    setState({
      detail_material: false,
      visible_outgoing: true,
    });
  };
  const pilihPart = (record) => {
    if(value=='manual'){
      setData({
        ...data,
        part_name: record?.description,
        part_number: record?.part_number,
        pcs_kanban: (record?.qty) - (record?.reduce_qty?? 0),
        split_count: record?.split_count?? 0,
        unique_code: record?.unique_code
      });
    }else{
      setData({
        ...data,
        part_name: record?.part_name,
        part_number: record?.part_number,
        pcs_kanban: (record?.last_stock) - (record?.stock_outgoing?? 0),
        unique_code: record?.unique_code
      });
    }
    
    setQrId(record.qr_code)
    setValue(value)
    setState({
      detail_material: false,
      visible_outgoing: true,
    });
  };

  const handleReset = () => {
    setData({
      ...data,
      po_number: "",
      dn_number: "",
      part_name: "",
      stock_outgoing: "",
      part_number: "",
      pcs_kanban: "",
      split_count: "",
      date: moment(new Date()), 
      warehouse: "", 
      unique_code:""   
    });

    
    setDataDisabled({
      ...setDataDisabled,
      po_number: false,
      dn_number: true,
      stock_outgoing: true,
      qty: true,
      unique_code: true
    });
  };
  const columnPartManual = [
    {
      title: "Section",
      key: 'sequence',
      sorter: true,
      render: (record) => (
          record.sequance?? record.sequence
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: 'description',
      sorter: true,
    },
    {
      title: "Current Stock",
      key: 'qty',
      sorter: true,
      render: (record) => (
          record.reduce_qty?  (record.qty - record.reduce_qty): record.qty
      ),
    },
    {
      title: "Action",
      key: 'action',
      sorter: true,
      render: (record) => (
          <Button key={record._id} type="primary" size="small" onClick={()=>pilihPart(record)}>Pilih</Button>
      ),
    },
  ]
  const columnPartCustom = [
    {
      title: "Unique Code",
      dataIndex: "unique_code",
      key: 'unique_code',
    },
    {
      title: "Description",
      dataIndex: "part_name",
      key: 'part_name',
      sorter: true,
    },
    {
      title: "Current Stock",
      key: 'last_stock',
      sorter: true,
      render: (record) => (
          record.last_stock?  ( record.last_stock - record.stock_outgoing ): record.stock_outgoing
      ),
    },
    {
      title: "Action",
      key: 'action',
      sorter: true,
      render: (record) => (
          <Button key={record._id} type="primary" size="small" onClick={()=>pilihPart(record)}>Pilih</Button>
      ),
    },
  ]
  const columns2 = [
    {
      title: "Part Number",
      dataIndex: "part_number",
      sorter: true,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      sorter: true,
    },
    {
      title: "No Invoice",
      dataIndex: "no_invoice",
      sorter: true,
    },
    {
      title: "Unique Code",
      dataIndex: "unique_code",
      sorter: true,
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse",
      sorter: true,
    },
    {
      title: "Outgoing",
      align: "right",
      sorter: true,
      render: (record) => (
          record.stock_outgoing + " " + record.satuan
      ),
    },
    {
      title: "Scan Date",
      dataIndex: "scan_date",
      sorter: true,
    },
    {
      title: "Scan By",
      dataIndex: "created_by",
      sorter: true,
    },
  ];
  const columns = [
    {
      title: "Part Number",
      dataIndex: "part_number",
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
    },
    {
      title: "Total Outgoing",
      render: (record) => (
          record.total_out + " " + record.satuan
      ),
      sorter: (a, b) => a.total_out - b.total_out
    },
    {
      title: "Last Scan Date",
      dataIndex: "scan_date",
      sorter: (a, b) => moment(a.scan_date).unix() - moment(b.scan_date).unix()
    },
  ];
  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const { Option } = Select;

  function onChange(value) {
    setData({
      ...data,
      warehouse: value,
    });
  }

  const radioChange = (e) => {
    handleReset()
    setValue(e.target.value);
    e.target.value=="manual" && handleGetParts("","manual")
    e.target.value=="custom" && handleGetParts("","custom")
   
    setData({
      ...data,
      dn_number: "",
      part_name: "",
      pcs_kanban: "",
      split_count: "",
      part_number: "",
      unique_code: ""
    });
  };

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  
  function printSingleHandler2 (data){
    const dataSelected = [data]
    Axios.post(
      `${url}/print-label2`,
      {
        payload:  [{"key" : data._id}],
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.status) {
        history.push('/printLabelMaterial', { state: { dataSelected }   })
      }else{
        message.error("Prosess Print Failed!");
      }
    })
    .catch((err) => {
      if (err.response) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message);
      }
    })
  }



  function printSingleHandler (data){
    const dataSelected = [data]
    history.push('/printLabelMaterial', { state: { dataSelected } })
  }
  const handleChangeDate = (value) => {
    if (value) {
      const [startDate, endDate] = value;
      setData({
        ...data,
        start_date: startDate,
        end_date: endDate
      });


    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined
      });
    }
  };

  
  return (
    <Fragment>
      <Modal
        title="Scan Outgoing"
        visible={state.visible_outgoing}
        onCancel={handleCancel}
        destroyOnClose={handleCancel}
        footer={[
          <Button key="reset" onClick={handleReset}>
            Reset
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={saveData}
          >
            Submit
          </Button>,
        ]}
      >
      
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Scan by :</label>
          </Col>
          <Col span={16}>
            <Radio.Group onChange={radioChange} defaultValue={value}>
              <Radio value="label" checked={value === "label" ? true : false}>
                Label
              </Radio>
              <Radio value="manual" checked={value === "manual" ? true : false}>
                Manual
              </Radio>
              <Radio value="custom" checked={value === "custom" ? true : false}>
                Custom
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            {value === "label" && <label>Kanban</label>}
            {value === "manual" && <label>Part Number</label>}
            {value === "custom" && <label>Part Number</label>}
          </Col>
          <Col span={16}>
            {value === "label" && (
              <Input
                onPressEnter={(e) => scanData(e, "dn_number")}
                prefix={<QrcodeOutlined />}
                //disabled={dataDisabled.dn_number}
                onChange={(e) => handleChange("dn_number", e.target.value)}
                value={data.dn_number}
                ref={DnRef}
              />
            )}
            {value === "manual"  && (
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Part Number"
                //onFocus={() => handleGetParts("")}
                onSearch={(value) => handleGetParts(value,'manual')}
                onChange={(value) => handleSetData(value)}
                value={data.part_number}
                allowClear={true}
              >
                {parts &&
                  parts.map((part) => {
                    return (
                      <Select.Option
                        key={part.part_number}
                        value={part.part_number}
                      >
                        {part.part_number}  ( {part.no_invoice} )
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
            {value === "custom"  && (
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Part Number"
                //onFocus={() => handleGetParts("")}
                onSearch={(value) => handleGetParts(value,'custom')}
                onChange={(value) => handleSetData(value)}
                value={data.part_number}
                allowClear={true}
              >
                {parts &&
                  parts.map((part) => {
                    return (
                      <Select.Option
                        key={part.part_number}
                        value={part.part_number}
                      >
                        {part.part_number} 
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Part Name</label>
          </Col>
          <Col span={16}>
            <Input
              disabled
              onChange={(e) => handleChange("part_name", e.target.value)}
              value={data.part_name}
            />
          </Col>
        </Row>
        
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Current Stock</label>
          </Col>
          <Col span={8}>
            <input className="ant-input"
             type='number'
              disabled={value!="custom"?true:false}
              value={data.pcs_kanban}
              onChange={(e) => handleChange("pcs_kanban", e.target.value)}
            />
          </Col>
        </Row>
        
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Quantity</label>
          </Col>
          <Col span={8}>
            <input className="ant-input"
               type='number'
              onChange={(e) => handleChange("stock_outgoing", e.target.value)}
              value={data.stock_outgoing}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Unique Code</label>
          </Col>
          <Col span={8}>
            <Input
              disabled={value!="custom"?true:false}
              onChange={(e) => handleChange("unique_code", e.target.value)}
              value={data.unique_code}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Date</label>
          </Col>
          <Col span={16}>
            <DatePicker
              placeholder="Date"
              value={data.date}
              onChange={(value) => handleChange("date", value)}
              format="DD MMMM YYYY"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8} style={{ fontWeight: "bold" }}>
            <label>Warehouse</label>
          </Col>
          <Col span={16}>
            <Select
              value={data.warehouse}
              showSearch
              style={{ width: 200 }}
              placeholder="Select Warehouse"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="WHRM01">WHRM 01</Option>
              <Option value="WHRM02">WHRM 02</Option>
            </Select>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Detail List Part Number Ready"
        visible={state.detail_material}
        onCancel={handleCancelModalDetail}
        destroyOnClose={handleCancelModalDetail}
        footer={null}
        key={"detailmaterial"}
      >
      
        <Table dataSource={partsDetail} key={"tabledetailmaterial"} columns={value=='manual'? columnPartManual : columnPartCustom} />
      </Modal>

      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>Scan Outgoing</Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>Scan Outgoing</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-between">
                <Col md={11}>
                  <Space>
                    <Button type="primary" onClick={showModal}>
                      <ScanOutlined />
                      Scan
                    </Button>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    />
                </Col>
                <Col xs={24} md={12} lg={5} className="align-right">
                  <Search
                    allowClear
                    placeholder="Search by Part Number"
                    onSearch={(value) => handleSearch(value)}
                    defaultValue={sessionStorage.getItem("keyword")}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={outgoing}
                    rowKey={(data, index) => index}
                    // pagination={{
                    //   pageSize: 10,
                    //   current: page,
                    //   total: outgoing.total,
                    //   showSizeChanger: false,
                    // }}
                    loading={loading}
                    //onChange={handleTableChange}
                    expandable={{
                      expandedRowRender: function(record, index, indent, expanded){
                        const cols = [
                          {
                            title: "No Invoice",
                            dataIndex: "no_invoice",
                            key: 'no_invoice',
                            filters: record.filter_invoice,
                            onFilter: (value, record) => record.no_invoice?.indexOf(value) === 0,
                          },
                          {
                            title: "Unique Code",
                            dataIndex: "unique_code",
                            key: 'unique_code',
                            filters: record.filter_uniqcode,
                             onFilter: (value, record) => record.unique_code?.indexOf(value) === 0,
                          },
                          {
                            title: "Warehouse",
                            dataIndex: "warehouse",
                            key: 'warehouse',
                          },
                          {
                            title: "Outgoing",
                            key: 'stock_outgoing',
                            sorter: (a, b) => a.stock_outgoing - b.stock_outgoing,
                            render: (record) => (
                                record.stock_outgoing + " " + record.satuan
                            ),
                          },
                          {
                            title: "Scan Date",
                            dataIndex: "scan_date",
                            key: 'scan_date',
                            sorter: (a, b) => moment(a.scan_date).unix() - moment(b.scan_date).unix()
                          },
                          {
                            title: "Scan By",
                            dataIndex: "created_by",
                            key: 'created_by',
                          },
                          {
                            title: 'Action',
                            render: (record) => 
                            <Button onClick={() => printSingleHandler(record)}  type="primary" shape="round" icon={<PrinterOutlined />} size="small">Re-Print</Button>
                          },
                         
                        ]
                       
                        return <>
                          <Row>
                            <Col xs={24}>
                          <Table columns={cols}  dataSource={record.detail} pagination={false} rowKey={(data, index2) => index2}/>
                          </Col>
                          </Row>
                          </>
                        // return 
                        
                        // <Table columns={cols} dataSource={record.detail} pagination={false} rowKey={(data, index2) => index2}/>;
                      }
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Outgoing;
