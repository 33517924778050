import React, {useState, useEffect} from 'react'
import {Tooltip, message} from 'antd';
import { url } from "../../../config/global";
import Axios from 'axios';

const ReceiveTooltip = ({record, receiving}) => {
    console.log(record, "record");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    return (
        <div>
            <Tooltip 
                title={
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td>No Invoice</td>
                            <td>:</td>
                            <td>{record.length > 0 ? record[0].no_invoice : null}</td>
                        </tr>
                        <tr>
                            <td>Receive By</td>
                            <td>:</td>
                            <td>{record.length > 0 ? record[0].created_by : null}</td>
                        </tr>
                        <tr>
                            <td>Leader</td>
                            <td>:</td>
                            <td>{record.length > 0 ? record[0].approved_by : null}</td>
                        </tr>
                        <tr>
                            <td>Total Qty</td>
                            <td>:</td>
                            <td>{record.length > 0 ? record[0].total_qty : null}</td>
                        </tr>
                        <tr>
                            <td>Sourcing</td>
                            <td>:</td>
                            <td>{record.length > 0 ? record[0].sourcing : null}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                }
                color={'#6e6d6d'}
                trigger={['click']}
            >
                <span>{receiving}</span>
            </Tooltip>
        </div>
    )
}

export default ReceiveTooltip