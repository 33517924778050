import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Tag,
  message,
  Button,
} from 'antd';
import { PrinterOutlined, MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState, Fragment } from 'react';
import Axios from 'axios';
import {url} from '../../../config/global'
import HistoryScan from './HistoryScan';
import LeaderCheck from './LeaderCheck';

function DetailInvoice({ id, rows, no_invoice, po_number }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(5);
    const [keyword, setKeyword] = useState('');
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend',
    });
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [modalLeader, setModalLeader] = useState(false);
    const [isLeaderDisabled, setIsLeaderDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [item, setItem] = useState({
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      sourcing: '',
      description: '',
      total_qty: '',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part: '',
      label_kemasan: '',
      remarks: '',
  })
  const [error, setError] = useState({
    qty_actual_receipt: ''
  })


    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    useEffect(() => {
        getDetails();
        return () => {
            setData([]);
        };
    }, [page, perpage, sort, keyword, no_invoice, po_number]);

    const getDetails = () => {
        setLoading(true);
        Axios.get(`${url}/invoice-get-detail`, {
            params: {
                keyword,
                no_invoice:no_invoice,
                po_number:po_number,
                perpage: perpage,
                page,
                sort: sort.sort,
                order: sort.order,
                columns: ['part_number', 'description'],
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePrint = (id) => {
        setLoading(true);
        Axios.get(`/generate-label/history/${id}/print`, {
            responseType: 'blob',
        })
            .then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                getDetails();
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    message.error('Server error');
                } else {
                    if (error.response) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error('Error:', error.message.message);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleReprintAll = async (record) => {
      setLoading(true);
      try {
          const { data } = await Axios.get(`${url}/warehouse-checksheets/reprint-all`, {
              params: {
                part_number : record.part_number,
                no_invoice : record.number,
                po_number : record.po_number,
              },
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
              responseType: 'blob', // Move responseType here
          });
  
          // Check if data exists
          if (data) {
              const file = new Blob([data], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          } else {
              message.error('No data returned from server.');
          }
      } catch (error) {
          if (error.response) {
              if (error.response.status === 500) {
                  message.error('Server error');
              } else {
                  const reader = new FileReader();
                  reader.onload = function () {
                      try {
                          const errorMessage = JSON.parse(reader.result);
                          message.error(errorMessage.message || 'Error processing the request.');
                      } catch (err) {
                          message.error('Error reading the error message.');
                      }
                  };
                  reader.readAsText(error.response.data);
              }
          } else {
              message.error(`Error: ${error.message}`);
          }
      } finally {
          setLoading(false);
          getDetails();
      }
    };

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const showLeaderModal = () => {
      console.log(modalLeader, "leader modal open");
      setError(null);
      setModalLeader(true);
      setIsLeaderDisabled(true);
      setIsDisabled(true);
      setSearchDisabled(true);
    };



  // Fungsi submit modal koreksi user leader
  const handleOkLeaderModal = async () => {
    if(item.qty_actual_receipt === ''){
      message.error("Qty Actual Receipt is required")
    }else {
      setModalLeader(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      setIsLeaderDisabled(true);
      await Axios.post(`${url}/warehouse-checksheets/submit-leader-invoice/${item.id}`,{
        no_invoice: item.no_invoice,
        po_number: item.po_number,
        qty: item.qty_actual_receipt,
        unique_code: item.unique_code,
        remarks: item.remarks,
        label_part: item.label_part,
        label_kemasan: item.label_kemasan,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            id: '',
            no_invoice: '',
            po_number: '',
            part_number: '',
            part_number_external: '',
            description: '',
            total_qty: '',
            balance: '',
            satuan: '',
            sourcing: '',
            qty_actual_receipt: '',
            tgl_receipt: '',
            unique_code: '',
            label_part : '',
            label_kemasan: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        setIsLeaderDisabled(false);
        setSearchDisabled(false);
        getDetails();
      })
    }
  };

  // Fungsi untuk menutup modal leader
  const handleCancelLeaderModal = () => {
    setModalLeader(false);
    setIsLeaderDisabled(false);
    setIsDisabled(false);
    setSearchDisabled(false);
    setItem({
      ...item,
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      description: '',
      total_qty: '',
      balance: '',
      satuan: '',
      sourcing: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part : '',
      label_kemasan: '',
    });
  };

    const pagination = {
        pageSize: 5,
        current: page,
        total: total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      };

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };

    const columns = [
        // {
        //     title: 'NO',
        //     dataIndex: 'no',
        //     key: 'no',
        //     align: 'center',
        //     width:100,
        //     fixed: 'left',
        //     render: (text, record, index) => {
        //         return (pagination.current - 1) * pagination.pageSize + index + 1;
        //     }
        // },
        {
          title: 'PART NUMBER',
          dataIndex: 'part_number',
          key: 'part_number',
          align:'center',
          width:200,
        },
        {
          title: 'DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          align:'center',
        },
        {
          title: 'SNP',
          align:'center',
          width:50,
          // dataIndex: 'snp',
          // key: 'snp',
          render: (text,record,index) => {
            return (formattedNumber(record.snp));
          }
        },
        {
          title: 'PACKING LIST',
          align:'center',
          children: [
            {
              title: 'PACKAGES',
              align: 'center',
              children:[
                {
                  dataIndex: 'packages',
                  key: 'packages',
                  align:'center',
                  width:50,
                },
                {
                  dataIndex: 'uom',
                  key: 'uom',
                  align:'center',
                  width:50,
                },
              ]
            },
            {
              title:'QUANTITY',
              align:'center',
              width:50,
              // dataIndex:'quantity',
              // key:'quantity',
              render: (text,record,index) => {
                return (formattedNumber(record.quantity));
              }
            },
            {
              title:'SATUAN',
              dataIndex:'uom',
              key:'uom',
              align:'center',
              width:50,
            },
          ],
        },
        {
          title: 'ACTUAL',
          align:'center',
          children: [
            {
              title:'QUANTITY',
              align:'center',
              width:50,
              // dataIndex:'qty_actual',
              // key:'qty_actual',
              render: (text,record,index) => {
                return (formattedNumber(record.qty_actual));
              }
            },
            {
              title:'TOTAL PACKAGES',
              dataIndex:'count_package',
              key:'count_package',
              align:'center',
              width:50,
            },
            {
              title:'SATUAN',
              dataIndex:'uom',
              key:'uom',
              align:'center',
              width:50,
            },
            {
              title:'BALANCES',
              align:'center',
              width:50,
              // dataIndex:'balance',
              // key:'balance',
              render: (text,record,index) => {
                return (formattedNumber(record.balance));
              }
            },
          ],
        },
        {
          title : 'CHECK ITEM',
          align:'center',
          children: [
            {
              title: 'LABEL PART',
              align: 'center',
              // dataIndex: 'label_part',
              // key:'label_part',
              render: (text,record,index) => {
                return `${record.label_part}%`;
              }
            },
            {
              title: 'KEMASAN',
              align: 'center',
              // dataIndex: 'kemasan',
              // key:'kemasan',
              render: (text,record,index) => {
                return `${record.kemasan}%`;
              }
            },
          ]
        },
        {
          title : 'PIC',
          align : 'center',
          children : [
            {
              title : 'RECEIVE BY',
              align : 'center',
              dataIndex:'created_by',
              key:'created_by',
            }
          ]
        },
        {
          title : 'LEADER',
          align : 'center',
          dataIndex:'approved_by',
          key:'approved_by',
        },
        {
          title : 'ACTION',
          align : 'center',
          render: (text,record,index) => {
            return (
              // <Fragment>
              //   <Dropdown className="pointer" overlay={
              //       <Menu>
              //           <Menu.Item key="1" onClick={() => handleReprintAll(record)}  disabled={record.count_receiving == 0}>
              //             Reprint All
              //           </Menu.Item>
              //           <Menu.Item key="2">
              //           <Button onClick={showLeaderModal}  disabled={record.count_receiving == 0}>Leader Check</Button>
              //             <LeaderCheck record={record} visible={modalLeader} onOk={handleOkLeaderModal} onCancel={handleCancelLeaderModal} item={item}
              //             setItem={setItem} />
              //         </Menu.Item>
              //       </Menu>
              //   } trigger={['click']}>
              //       <span className="pointer" onClick={e => e.preventDefault()}>
              //           <MoreOutlined />
              //       </span>
              //   </Dropdown>
              // </Fragment>
              <Fragment>
                <Dropdown className="pointer" overlay={
                    <Menu>
                        <Menu.Item key="1" onClick={() => handleReprintAll(record)}  disabled={record.count_receiving == 0}>
                          Reprint All
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Button type="text"
                            style={{
                              border: 'none',
                              textAlign: 'left', 
                              padding: 0, // optional, to remove padding if needed
                            }}disabled={record.balance != 0} onClick={() => showLeaderModal()}>Leader Check</Button>
                            {
                              modalLeader && (
                                <LeaderCheck record={record} visible={modalLeader} onOk={handleOkLeaderModal} onCancel={handleCancelLeaderModal} disabled={isLeaderDisabled} item={item}
                                setItem={setItem} />
                              )
                            }
                          </Menu.Item>
                    </Menu>
                } trigger={['click']}>
                    <span className="pointer" onClick={e => e.preventDefault()}>
                        <MoreOutlined />
                    </span>
                </Dropdown>
              </Fragment>
            );
          }
        },
      ];
    return (
        <Card>
            <Row justify="end">
                <Col>
                    <Input.Search
                        allowClear="true"
                        onSearch={(value) => setKeyword(value)}
                        placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col xs={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: true }}
                        expandable={{
                          expandedRowKeys: expandedRowKeys,
                          onExpand: handleExpand,
                          expandedRowRender: (record) => (
                              <HistoryScan
                                  key={`history-scan-${record._id}`}
                                  id={record._id}
                                  rows={expandedRowKeys}
                                  part_number={record.part_number}
                                  no_invoice={record.number}
                                  po_number={record.po_number}
                              />
                          ),
                      }}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default DetailInvoice;
