import React, { useState, useEffect, useMemo, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message, Space, Tooltip} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined} from '@ant-design/icons'
import moment from 'moment';
import ReceiveTooltip from './components/ReceiveTooltip';


const { Title } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker

const WarehouseInventory = () => {
    const [data, setData] = useState({
        start_date: moment().startOf('month'),
        end_date: moment()
    })
    // const beginning = 200;
    const [warehouses, setWarehouses] = useState([]) // Use this for the table data
  
    const generateColumns = (startDate, endDate) => {
      const columns = [];
      let currentMonth = '';
      const dateColumns = [];
      const currentDate = moment(startDate);
      const end = moment(endDate);
  
      const dailyOnHand = {}; // Store the on_hand value for each date and part_number
  
      while (currentDate.isSameOrBefore(end)) {
          const monthYear = currentDate.format('MMM-YY');
  
          if (monthYear !== currentMonth) {
              if (currentMonth !== '') {
                  columns.push({
                      title: currentMonth,
                      children: dateColumns.slice(),
                  });
                  dateColumns.length = 0; // Clear date columns for the new month
              }
              currentMonth = monthYear;
          }
  
          const date = currentDate.format('YYYY-MM-DD'); // Date string in 'YYYY-MM-DD' format
          const key = currentDate.format('DD-MM-YYYY'); // Key for the column
          const previousDay = moment(currentDate).subtract(1, 'day').format('YYYY-MM-DD');
          dateColumns.push({
              title: currentDate.date().toString(),
              dataIndex: key,
              key: key,
              width: 50,
              align: 'center',
              render: (text, record) => {
                  const partNumber = record.part_number_internal;
                  const min = record.min_stock || 0;
                  const keyPart = `${partNumber}-${date}`;
  
                  // Determine if it's the first day of the data range
                  const isFirstDay = currentDate.isSame(startDate, 'day');

                  // Get the previous day's on_hand value if it exists
                  const previousOnHand = dailyOnHand[`${partNumber}-${previousDay}`];

                  // Use the record's beginning value on the first day or previous on_hand value if available
                  const beginning = isFirstDay
                      ? record.beginning
                      : (previousOnHand !== undefined ? previousOnHand : record.beginning);
  
                  const receiving = record.receivings
                      .filter(item => item.part_number === partNumber && item.date === date)
                      .reduce((sum, item) => sum + item.totalSum, 0);
                  
                  const dataReceiving = record.receivings
                      .filter(item => item.part_number === partNumber && item.date === date)
  
                  const outgoing = record.outgoings
                      .filter(item => item.part_number === partNumber && moment(parseInt(item.date.$date.$numberLong)).format('YYYY-MM-DD') === date)
                      .reduce((sum, item) => sum + item.totalSum, 0);

                  const dataOutgoing = record.outgoings
                      .filter(item => item.part_number === partNumber && moment(parseInt(item.date.$date.$numberLong)).format('YYYY-MM-DD') === date);
  
                  // Calculate on_hand for the current date using the appropriate beginning value
                  const on_hand = (beginning + receiving) - outgoing;
  
                  // Save the on_hand value for use in the next day's calculation
                  dailyOnHand[keyPart] = on_hand;
  
                  return (
                      <table border={0} style={{ padding: '0', textAlign:'center' }}>
                          <tbody>
                              <tr>
                                  <td style={{border:0, fontSize:14, cursor:receiving !== 0?'pointer':''}}>
                                    {receiving !== 0 ? (
                                      <ReceiveTooltip record={dataReceiving} receiving={receiving} />
                                    ) : (
                                      <span>{receiving}</span>
                                    )}
                                  </td>
                              </tr>
                              <tr>
                                  <td style={{border:0, fontSize:14, cursor:outgoing !== 0?'pointer':''}}>
                                  {outgoing !== 0 ? (
                                      <ReceiveTooltip record={dataOutgoing} receiving={outgoing} />
                                    ) : (
                                      <span>{outgoing}</span>
                                    )}
                                  </td>
                              </tr>
                              <tr>
                                  <td style={{border:0, fontSize:14, color: on_hand < min ? 'red' : 'rgba(0, 0, 0, 0.65)'}}>{on_hand}</td>
                              </tr>
                          </tbody>
                      </table>
                  );
              },
          });
  
          currentDate.add(1, 'day'); // Move to the next day
      }
      
  
      if (dateColumns.length > 0) {
          columns.push({
              title: currentMonth,
              children: dateColumns,
              // width: 100,
              align: 'center',
          });
      }
  
      return columns;
  };
    const handleSearch = (value) => {
      setKeyword(value);
      // sessionStorage.setItem("keyword", value);
      setPage(1);
  
      if (value === "") {
        getData();
      }
    };
    const [dateColumns, setDateColumns] = useState([]);
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'name',
        order: 'ascend'
    })
    const [keyword, setKeyword] = useState('')
    const handleChangeDate = (value) => {
      if (value) {
        const [startDate, endDate] = value;
        setData({
          ...data,
          start_date: startDate,
          end_date: endDate
        });
  
        // Generate new columns based on the updated date range
        const newDateColumns = generateColumns(startDate, endDate);
        setDateColumns(newDateColumns);
  
      } else {
        setData({
          ...data,
          start_date: undefined,
          end_date: undefined
        });
        setDateColumns([]);
      }
    };
  
    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }
  
    const getData = () => {
      console.log("generate data");
        setLoading(true);
        Axios.get(`${url}/warehouse-inventory`, {
            params: {
                keyword,
                columns: [
                    'part_number_internal',
                    'part_number_external',
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date).format("YYYY-MM-DD")
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
          console.log(res.data);
          setWarehouses(res.data)
          setDateColumns(generateColumns(data.start_date, data.end_date));
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    
    useEffect(() => {
        getData()
    }, [keyword, page, sort, data.start_date, data.end_date])
  
    const baseColumns = [
      {
        title: 'NO',
        dataIndex: 'no',
        key: 'no',
        align: 'center',
        width:100,
        // fixed: 'left',
        render: (text, record, index) => {
          return (pagination.current - 1) * pagination.pageSize + index + 1;
        }
      },
      // {
      //   title: 'ADDRESS',
      //   dataIndex: 'address',
      //   key: 'address',
      //   width:100,
      //   fixed: 'left',
      //   align: 'center',
      // },
      // {
      //   title: 'DIV',
      //   dataIndex: 'div',
      //   key: 'div',
      //   width:100,
      //   fixed: 'left',
      //   align: 'center',
      // },
      {
        title: 'PART ERP',
        dataIndex: 'part_number_internal',
        key: 'part_number_internal',
        width:200,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'PART NO',
        dataIndex: 'part_number_external',
        key: 'part_number_external',
        width:100,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'DESCRIPTION',
        dataIndex: 'part_name',
        key: 'part_name',
        width:150,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'SNP',
        dataIndex: 'snp',
        key: 'snp',
        width:100,
        // fixed: 'left',
        align: 'center',
      },
      {
        title: 'STANDARD',
        dataIndex: 'data',
        width: 200,
        // fixed: 'left',
        align: 'center',
        render: (text, record, index) => (
          <table border={0} style={{padding: '0', borderRight: 0}}>
            <tbody>
              <tr>
                <td style={{ paddingRight: '30px', border:0, fontSize:14 }}>MIN</td>
                <td style={{border:0, fontSize:14}}>
                  { record.min_stock || '0' }
                  <Dropdown className="pointer" overlay={
                    <Menu>
                        <Menu.Item key="1">
                         <Link to={`/item-group/edit/${record._id}`}><EditOutlined /> Edit MIN / MAX</Link>
                        </Menu.Item>
                    </Menu>
                } trigger={['click']}>
                    <span className="pointer" onClick={e => e.preventDefault()}>
                        <MoreOutlined />
                    </span>
                </Dropdown>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '30px' , border:0, fontSize:14  }}>MAX</td>
                <td style={{border:0, fontSize:14}}>
                  { record.max_stock || '0'}
                  {/* <Dropdown className="pointer" overlay={
                      <Menu>
                          <Menu.Item key="1">
                            <Link to={`/item-group/edit/${record._id}`}><EditOutlined /> Edit MAX</Link>
                          </Menu.Item>
                      </Menu>
                  } trigger={['click']}>
                      <span className="pointer" onClick={e => e.preventDefault()}>
                          <MoreOutlined />
                      </span>
                  </Dropdown> */}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: '30px' , border:0 , fontSize:14 }}>BEGINNING</td>
                <td style={{border:0 , fontSize:14}}>{ record.beginning || '0' }</td>
              </tr>
            </tbody>
          </table>
        ),
      },
      {
        title: 'CONTROL',
        dataIndex: 'control',
        key: 'control',
        width:150,
        // fixed: 'left',
        align: 'center',
        render: (text, record, index) => {
          return (<table border={0} style={{ padding: '0', width: '100px'}}>
            <tbody>
              <tr>
                <td style={{border:0, fontSize:14}}>Actual Receipt</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>Actual Out</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>On Hand</td>
              </tr>
            </tbody>
          </table>);
        },
      },
      {
        title: 'TOTAL',
        dataIndex: 'total',
        key: 'total',
        width:100,
        // fixed: 'left',
        align: 'center',
        render: (text, record, index) => {
           const totalReceiving = record.receivings
            .reduce((sum, item) => sum + item.totalSum, 0);
           const totalOutgoing = record.outgoings
            .reduce((sum, item) => sum + item.totalSum, 0);
          let totalOnHand = (record.beginning + totalReceiving) - totalOutgoing;
          return (<table style={{ textAlign: 'center', fontSize:14 }}>
            <tbody>
              <tr>
                <td style={{border:0, fontSize:14}}>{ totalReceiving }</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>{ totalOutgoing }</td>
              </tr>
              <tr>
                <td style={{border:0, fontSize:14}}>{ totalOnHand }</td>
              </tr>
            </tbody>
          </table>);
        },
      },
    ];
  
    const memoizedColumns = useMemo(() => {
      return generateColumns(data.start_date, data.end_date);
  }, [data.start_date, data.end_date]);
    
    const allColumns = [...baseColumns, ...dateColumns];
    // const allColumns = baseColumns;
  
    const pagination = {
      pageSize: 10,
      current: page,
      total: warehouses.total,
      showSizeChanger: false,
      onChange: (page) => setPage(page),
    };
  
    if (!sessionStorage.getItem('token')) {
      return <Redirect to="/login" />
    }
  
    return (
       <Fragment>
        <Row>
          <Col xs={24}>
            <Card>
              <Title level={4}>Warehouse Inventory</Title>
              <Row type="flex" justify="left">
                <Breadcrumb>
                  <Breadcrumb.Item>Warehouse Inventory</Breadcrumb.Item>
                </Breadcrumb>
              </Row>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                    <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    />
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    defaultValue={sessionStorage.getItem('keyword')}
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col xs={24}>
                  <Table
                    columns={allColumns}
                    dataSource={warehouses.data}
                    rowKey={(data) => data._id} /// Make sure this is an array
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    bordered
                    scroll={{ x: 1000 }}
                  />
                </Col>  
              </Row>
            </Card>
          </Col>
        </Row>
      </Fragment>
    )
  }

export default WarehouseInventory