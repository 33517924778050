import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Breadcrumb, DatePicker, Row,Col,Button,Modal,Input,Table, message,Select, Space, Form, Badge, Checkbox} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, UploadOutlined, CaretDownOutlined, QrcodeOutlined, CameraOutlined, RedoOutlined, BarcodeOutlined} from '@ant-design/icons'
import moment from 'moment';
import { render } from 'less';
import QrReader from 'react-qr-reader';
// import { BarcodeScanner } from "react-barcode-qrcode-scanner";
import './ChecksheetWarehouse.less'
import LeaderCheck from './Component/LeaderCheck';
import BarcodeScanner from './Component/BarcodeScanner';

const { Title, Text } = Typography
const { Search } = Input
const { Content } = Layout
const { RangePicker} = DatePicker
const { Option } = Select;

const layout = {
    labelCol: {
        lg: 10,
        md: 10
    },
    wrapperCol: {
        lg: 14,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

const ChecksheetWarehouse = () => {
  const [formattedDate, setFormattedDate] = useState(moment().format('dddd DD MMM YYYY HH:mm:ss'));
  const [date, setDate] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedDate(moment().format('dddd DD MMM YYYY HH:mm:ss'));
      setDate(moment().format('YYYY/MM/DD HH:mm:ss'));
    }, 1000);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState({
      sort: 'name',
      order: 'ascend'
  })

  const [checkSheets, setCheckSheet] = useState([]) // Use this for the table data
  const [dataInvoices, setDataInvoices] = useState([]) // Use this for the table data

  const [keyword, setKeyword] = useState('')

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUniqueCodeModalVisible, setIsUniqisUniqueCodeModalVisible] = useState(false);
  const [modalBarcode, setModalBarcode] = useState(false);
  const [modalLeader, setModalLeader] = useState(false);
  const [isScanModalVisible, setIsScanModalVisible] = useState(false);
  const [isPartModalVisible, setIsPartModalVisible] = useState(false);
  const [isOkDisabled, setIsOkDisabled] = useState(false);
  const [isLeaderDisabled, setIsLeaderDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [itemDisabled, setItemDisabled] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const inputRef = useRef(null);
  const [scanMaterial, setScanMaterial] = useState('');
  const [success, setSuccess] = useState(true);
  const [messageQr, setMessageQr] = useState('');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [item, setItem] = useState({
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      sourcing: '',
      description: '',
      total_qty: '',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part: '',
      label_kemasan: '',
      remarks: '',
  })
  const [error, setError] = useState({
    qty_actual_receipt: ''
  })
  const [invoices, setInvoices] = useState({
    inv_id : '',
    inv_number : '',
  });
  const [checkLeader, setCheckLeader] = useState({
    part_number: '',
    number: '',
    po_number: '',
  })

  const handleTableChange = (pagination, filters, sorter) => {
      setPage(pagination.current)
      setSort({
          sort: sorter.field,
          order: sorter.order
      })
      getData(keyword, pagination.current, sort);
  }

  const pagination = {
    pageSize: 10,
    current: page,
    total: checkSheets.total,
    showSizeChanger: false,
    onChange: (page) => setPage(page),
  };

  // const handleSearch = (value) => {
  //   setKeyword(value);
  //   sessionStorage.setItem("keyword_invoice", value);
  //   setPage(1);
  //   if (value !== "") {
  //     getData(value, page, sort);
  //   }
  // };

  const handleChangeSelect = (name,value) => {
    if (value !== "") {
      // setKeyword(value.label)
      getData(value.label, page, sort);
    }
    setInvoices({
        ...invoices,
        [`${name}_id`]: value.value,
        [`${name}_number`]: value.label
    })
    setKeyword(value.label)
  }

  const getInvoices = async (keyword) => {
    await Axios.get(`${url}/invoice/inv-list`, {
        params: {
            keyword,
            columns: [
              'number',
              'po_number',
          ],
        },
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(res => {
        setDataInvoices(res.data.data)
    }).catch(err => {
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status == 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
    })
  }

  const getData = (keyword, page, sort) => {
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      Axios.get(`${url}/warehouse-checksheets`, {
          params: {
              keyword,
              columns: [
                  'number',
              ],
              perpage: 10,
              page,
              sort: sort.sort,
              order: sort.order,
          },
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
      }).then(res => {
        console.log(res.data);
        setCheckSheet(res.data)
        setTimeout(() => {
          inputRef.current.focus(); // Set focus after enabling
        }, 1000); // 
      }).catch(err => {
          if (err.response) {  
              message.error(err.response.data.message)
              if (err.response.status === 401) {
                  sessionStorage.removeItem('token')
              }
          } else {
              message.error('Backend not yet Started')
          }
      }).finally(() => {
          setIsDisabled(false)
          setSearchDisabled(false)
          setLoading(false)
      })
  }

  // Fungsi untuk menangani perubahan input berdasarkan _id
  const handleInputChange = (value, id, field) => {
    const updatedSheets = checkSheets.data.map((sheet) => {
      if (sheet._id === id) {
        return { ...sheet, [field]: value };  // Update field yang sesuai
      }
      return sheet;  // Jika _id tidak sesuai, kembalikan data sheet yang sama
    });
    setCheckSheet({
      ...checkSheets,  // Spread the existing checkSheets object
      data: updatedSheets // Update only the data property
    });  // Set kembali data yang telah diperbarui
  };

  const handleCheckPart = async (value, id, field) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/update-invoice/${id}`,{
      field: field,
      value: value,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
        setScanMaterial('');
        setMessageQr('Scan Qr Successfully');
    }).catch(err => {
        message.error(err.response.data.message)
        setMessageQr('Scan Qr Failed');
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setSuccess(false)
      setScanMaterial('');
      getData(keyword, page, sort);
    })
    // const updatedSheets = checkSheets.data.map((sheet) => {
    //   if (sheet._id === id) {
    //     return { ...sheet, [field]: value };  // Update field yang sesuai
    //   }
    //   return sheet;  // Jika _id tidak sesuai, kembalikan data sheet yang sama
    // });
    // setCheckSheet({
    //   ...checkSheets,  // Spread the existing checkSheets object
    //   data: updatedSheets // Update only the data property
    // });  // Set kembali data yang telah diperbarui
  };

  const handleCheckKemasan = async (value, id, field) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/update-invoice/${id}`,{
      field: field,
      value: value,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
        setScanMaterial('');
    }).catch(err => {
        message.error(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setSuccess(false)
      setScanMaterial('');
      getData(keyword, page, sort);
    })
    // const updatedSheets = checkSheets.data.map((sheet) => {
    //   if (sheet._id === id) {
    //     return { ...sheet, [field]: value };  // Update field yang sesuai
    //   }
    //   return sheet;  // Jika _id tidak sesuai, kembalikan data sheet yang sama
    // });
    // setCheckSheet({
    //   ...checkSheets,  // Spread the existing checkSheets object
    //   data: updatedSheets // Update only the data property
    // });  // Set kembali data yang telah diperbarui
  };
  
  const username = sessionStorage.getItem('fullname') ?? '-';
  const role_name = sessionStorage.getItem('role_name') ?? '-';

  const showModal = () => {
    setIsModalVisible(true);
    setSuccess(true);
  };

  const showUniqueCodeModal = () => {
    setIsUniqisUniqueCodeModalVisible(true);
    setSuccess(true);
  };

  const showBarcodeModal = () => {
    setModalBarcode(true);
    setSuccess(true);
  };

  const showScanModal = (record) => {
    setIsScanModalVisible(true);
    setItem({
      ...item,
      id: record._id,
      no_invoice: record.number,
      po_number: record.po_number,
      part_number:record.part_number,
      part_number_external:record.part_number_external,
      description:record.description,
      total_qty:record.quantity,
      balance:record.balance,
      satuan:record.uom,
      sourcing:record.sourcing,
      qty_actual_receipt:'',
      tgl_receipt:moment().format('YYYY/MM/DD'),
      unique_code:record.unique_code || '',
      label_part : '',
      label_kemasan: '',
      remarks: '',
    });
  };

  const showPartModal = (record) => {
    setIsPartModalVisible(true);
    setItem({
      ...item,
      id: record._id,
      no_invoice: record.number,
      part_number:record.part_number,
      part_number_external:record.part_number_external,
      po_number: record.po_number,
      description:record.description,
      total_qty:record.total_qty,
      balance:record.balance,
      sourcing:record.sourcing,
      satuan:record.uom,
      qty_actual_receipt:record.qty,
      tgl_receipt:moment().format('YYYY/MM/DD'),
      unique_code:record.unique_code,
      label_part : '',
      label_kemasan: '',
      remarks: '',
    });
  };

  const handleCancelScanModal = () => {
    setIsScanModalVisible(false);
    setItem({
      ...item,
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      description: '',
      total_qty: '',
      balance:'',
      satuan: '',
      sourcing: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part : '',
      label_kemasan: '',
      remarks: '',
    });
  };

  const handleCancelPartModal = () => {
    setIsPartModalVisible(false);
    setItem({
      ...item,
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      description: '',
      total_qty: '',
      balance:'',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part : '',
      label_kemasan: '',
      remarks: '',
      remarks: '',
    });
  };

  const handleOkScanModal = async () => {
    if(item.qty_actual_receipt === ''){
      message.error("Qty Actual Receipt is required")
    }else if(item.label_part === ''){
      message.error("Label Part is required")
    }else if (item.label_kemasan === ''){
      message.error("Kemasan is required")
    }else {
      setIsScanModalVisible(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      if(checkSheets.cups !== '' && checkSheets.cups === 'ON'){
        await Axios.post(`${url}/warehouse-checksheets/scan-manual`,{
          no_invoice: item.no_invoice,
          po_number: item.po_number,
          total_qty: item.total_qty,
          part_number: item.part_number,
          part_number_external: item.part_number_external,
          satuan: item.satuan,
          sourcing: item.sourcing,
          description: item.description,
          unique_code: item.unique_code,
          qty_actual_receipt: item.qty_actual_receipt,
          label_part: item.label_part,
          label_kemasan: item.label_kemasan,
        },{
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          responseType: 'blob',
        }).then(({ data }) => {
            message.success("Receipt Manual Successfully")
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setItem({
              ...item,
              id: '',
              no_invoice: '',
              po_number: '',
              part_number: '',
              part_number_external: '',
              description: '',
              total_qty: '',
              balance: '',
              satuan: '',
              sourcing: '',
              qty_actual_receipt: '',
              tgl_receipt: '',
              unique_code: '',
              label_part : '',
              label_kemasan: '',
              remarks: '',
            });
        }).catch(err => {
          if (error.response.status === 500) {
            message.error('Server error');
          } else {
            if (error.response) {
                const reader = new FileReader();
                reader.onload = function () {
                    const errorMessage = JSON.parse(reader.result);
                    message.error(errorMessage.message);
                };
                reader.readAsText(error.response.data);
            } else {
                message.error('Backend not yet Started')
            }
          }
        }).finally(() => {
          setLoading(false);
          setIsDisabled(false);
          setSearchDisabled(false);
          getData(keyword, page, sort);
        })
      }else {
        await Axios.post(`${url}/warehouse-checksheets/scan-manual`,{
          no_invoice: item.no_invoice,
          po_number: item.po_number,
          total_qty: item.total_qty,
          part_number: item.part_number,
          part_number_external: item.part_number_external,
          satuan: item.satuan,
          sourcing: item.sourcing,
          description: item.description,
          unique_code: item.unique_code,
          qty_actual_receipt: item.qty_actual_receipt,
          label_part: item.label_part,
          label_kemasan: item.label_kemasan,
        },{
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
        }).then(res => {
            message.success(res.data.message)
            setItem({
              ...item,
              id: '',
              no_invoice: '',
              po_number: '',
              part_number: '',
              part_number_external: '',
              description: '',
              total_qty: '',
              balance: '',
              satuan: '',
              sourcing: '',
              qty_actual_receipt: '',
              tgl_receipt: '',
              unique_code: '',
              label_part : '',
              label_kemasan: '',
              remarks: '',
            });
        }).catch(err => {
          console.log(err.response)
          if (err.response) {
              message.error(err.response.data.message)
              if (err.response.status === 422) {
                  setError(err.response.data.errors)
              }
              if (err.response.status === 401) {
                  sessionStorage.removeItem('token')
              }
          } else {
              message.error('Backend not yet Started')
          }
        }).finally(() => {
          setLoading(false);
          setIsDisabled(false);
          setSearchDisabled(false);
          getData(keyword, page, sort);
        })
      }
    }
  };

  const handleOkPartModal = async () => {
    if(item.label_part === ''){
      message.error("Label Part is required")
    }else if (item.label_kemasan === ''){
      message.error("Kemasan is required")
    }else {
      setIsPartModalVisible(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      if(checkSheets.cups !== '' && checkSheets.cups === 'ON'){
        await Axios.post(`${url}/warehouse-checksheets/update-invoice/${item.id}`,{
          no_invoice: item.no_invoice,
          part_number: item.part_number,
          unique_code: item.unique_code,
          qty_actual_receipt: item.qty_actual_receipt,
          label_part: item.label_part,
          label_kemasan: item.label_kemasan,
        },{
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          responseType: 'blob',
          }).then(({ data }) => {
            message.success("Scan QR Successfully")
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setItem({
              ...item,
              id: '',
              no_invoice: '',
              po_number: '',
              part_number: '',
              part_number_external: '',
              description: '',
              total_qty: '',
              balance: '',
              satuan: '',
              sourcing: '',
              qty_actual_receipt: '',
              tgl_receipt: '',
              unique_code: '',
              label_part : '',
              label_kemasan: '',
              remarks: '',
            });
          }).catch(err => {
            console.log(err.response)
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
          }).finally(() => {
            setLoading(false);
            setIsDisabled(false);
            setSearchDisabled(false);
            getData(keyword, page, sort);
          })
      }else {
        await Axios.post(`${url}/warehouse-checksheets/update-invoice/${item.id}`,{
        no_invoice: item.no_invoice,
        part_number: item.part_number,
        unique_code: item.unique_code,
        qty_actual_receipt: item.qty_actual_receipt,
        label_part: item.label_part,
        label_kemasan: item.label_kemasan,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
        }).then(res => {
            message.success(res.data.message)
            setItem({
              ...item,
              id: '',
              no_invoice: '',
              po_number: '',
              part_number: '',
              part_number_external: '',
              description: '',
              total_qty: '',
              balance: '',
              satuan: '',
              sourcing: '',
              qty_actual_receipt: '',
              tgl_receipt: '',
              unique_code: '',
              label_part : '',
              label_kemasan: '',
              remarks: '',
            });
        }).catch(err => {
          console.log(err.response)
          if (err.response) {
              message.error(err.response.data.message)
              if (err.response.status === 422) {
                  setError(err.response.data.errors)
              }
              if (err.response.status === 401) {
                  sessionStorage.removeItem('token')
              }
          } else {
              message.error('Backend not yet Started')
          }
        }).finally(() => {
          setLoading(false);
          setIsDisabled(false);
          setSearchDisabled(false);
          getData(keyword, page, sort);
        })
      }
    }
  };

  const handleChangeScanModal = (name, value) => {
    setError(null)
    setIsOkDisabled(false);
    const minus = parseInt(value) + parseInt(item.balance);
    console.log(minus, "minus");
    if(name === 'qty_actual_receipt' && minus > 0){
      setError({
        ...error,
        qty_actual_receipt: "QTY actual receipt more than " + item.total_qty
      });
      console.log(error);
      setIsOkDisabled(true);
    }else if(name === 'qty_actual_receipt' && minus < 0){
      setIsOkDisabled(false);
    }
    setItem({
        ...item,
        [name]: value
    })
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setSuccess(false);  // You can stop QR scanning here
  };

  const handleCancelQrUniqueCode = () => {
    setIsUniqisUniqueCodeModalVisible(false);
    setSuccess(false);  // You can stop QR scanning here
  };

  const handleCancelBarcodeModal = () => {
    setModalBarcode(false);
    setSuccess(false);  // You can stop QR scanning here
  };

  const handleRetry = () => {
      setSuccess(true)
  }

  const handleError = (err) => {
      console.log(err)
  }

  const handleScan = (value) => {
    if (value !== null) {
      console.log(value);
      setScanMaterial(value);
      setSuccess(false);
      setMessageQr('Scan Qr Successfully');
      setIsModalVisible(false);
      handlePostMaterial(value);
    }
  }

  const handleScanUniqueCode = (value) => {
    if (value !== null) {
      console.log(value);
      // setScanMaterial(value);
      setSuccess(false);
      setMessageQr('Scan Qr Successfully');
      setIsUniqisUniqueCodeModalVisible(false);
      handleChangeScanModal('unique_code', value);
      // handlePostMaterial(value);
    }
  }

  const handleScanBarCode = (value) => {
    if (value !== null) {
      console.log(value);
      // setScanMaterial(value);
      setSuccess(false);
      setMessageQr('Scan Barcode Successfully');
      setModalBarcode(false);
      handleChangeScanModal('unique_code', value);
      // handlePostMaterial(value);
    }
  }

  const handlePressScanMaterial = (event) => {
    if (event.key === 'Enter') {
      // Action to trigger on pressing Enter
      handlePostMaterial(scanMaterial);
    }
  };

  const handleChangeScanMaterial = (e) => {
    setScanMaterial(e.target.value);
  };

  const handlePostMaterial = async (value) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/check-qr`,{
      qr: value,
      no_invoice : invoices.inv_number
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(res => {
        message.success(res.data.message)
        setScanMaterial('');
        setMessageQr('Scan Qr Successfully');
        setIsPartModalVisible(true);
        showPartModal(res.data.item)
    }).catch(err => {
        message.error(err.response.data.message)
        setMessageQr('Scan Qr Failed');
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setScanMaterial('');
      getData(keyword, page, sort);
    })
  }

  const handleSubmitted = async (part_number,no_invoice) => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/submit`, {
      part_number: part_number,
      no_invoice: no_invoice,
    }, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      responseType: 'blob',
    }).then(({ data }) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }).catch(err => {
      if (error.response.status === 500) {
        message.error('Server error');
      } else {
        if (error.response) {
            const reader = new FileReader();
            reader.onload = function () {
                const errorMessage = JSON.parse(reader.result);
                message.error(errorMessage.message);
            };
            reader.readAsText(error.response.data);
        } else {
            console.error('Error:', error.message.message);
        }
      }
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setScanMaterial('');
      setSelectedKeys([]);
      getData(keyword, page, sort);
    })
  }

  const handlePrinted = async () => {
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/print-checksheet`, {
      no_invoice: keyword,
    }, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      responseType: 'blob',
    }).then(({ data }) => {
        setKeyword('');
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }).catch(err => {
      if (error.response.status === 500) {
        message.error('Server error');
      } else {
        if (error.response) {
            const reader = new FileReader();
            reader.onload = function () {
                const errorMessage = JSON.parse(reader.result);
                message.error(errorMessage.message);
            };
            reader.readAsText(error.response.data);
        } else {
            console.error('Error:', error.message.message);
        }
      }
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setScanMaterial('');
      setSelectedKeys([]);
      setKeyword('');
      // getData(keyword, page, sort);
      setInvoices({
          ...invoices,
          inv_id: '',
          inv_number: ''
      })
      getInvoices(keyword);
      setCheckSheet([]);
    })
  }

  const handleSubmittedAll = async () => {
    let data = [];
    if(selectedKeys.length === 0){
      data = (checkSheets.data || [])
      .filter((item) => item.is_submitted === 1)
      .map((item) => ({
        part_number: item.part_number,
        no_invoice: item.number,
      }));
    }else {
      data = selectedKeys
    }
    setLoading(true);
    setIsDisabled(true);
    setSearchDisabled(true);
    await Axios.post(`${url}/warehouse-checksheets/submit-all`,{
      data: data,
    },{
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      responseType: 'blob',
    }).then(({ data }) => {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }).catch(err => {
        message.error(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      setIsDisabled(false);
      setSearchDisabled(false);
      setScanMaterial('');
      setSelectedKeys([]);
      getData(keyword, page, sort);
    })
  }

  const formattedNumber = (value) => {
    return value.toLocaleString('id-ID')
  };

  // Menyimpan part_number dan no_invoice yang tidak di-disable
  const selectableKeys = (checkSheets.data || [])
  .filter((item) => item.is_submitted === 1)
  .map((item) => ({
    part_number: item.part_number,
    no_invoice: item.number,
  }));

  // Fungsi untuk memeriksa/uncheck semua checkbox yang tidak di-disable
  const onCheckAllChange = (e) => {
    setSelectedKeys(e.target.checked ? selectableKeys : []);
  };

  // Fungsi untuk memeriksa checkbox individu
  const onCheckChange = (part_number) => {
    setSelectedKeys((prevSelectedKeys) =>
      prevSelectedKeys.some((key) => key.part_number === part_number)
        ? prevSelectedKeys.filter((key) => key.part_number !== part_number)
        : [...prevSelectedKeys, selectableKeys.find((key) => key.part_number === part_number)]
    );
  };


  // Fungsi membuka menu koreksi user leader
  const showLeaderModal = () => {
    setError(null)
    setModalLeader(true);
    setIsLeaderDisabled(true);
    setIsDisabled(true);
    setSearchDisabled(true);
  };


  // Fungsi submit modal koreksi user leader
  const handleOkLeaderModal = async () => {
    if(item.qty_actual_receipt === ''){
      message.error("Qty Actual Receipt is required")
    }else {
      setModalLeader(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      setIsLeaderDisabled(true);
      await Axios.post(`${url}/warehouse-checksheets/submit-leader/${item.id}`,{
        no_invoice: item.no_invoice,
        po_number: item.po_number,
        qty: item.qty_actual_receipt,
        unique_code: item.unique_code,
        remarks: item.remarks,
        label_part: item.label_part,
        label_kemasan: item.label_kemasan,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            id: '',
            no_invoice: '',
            po_number: '',
            part_number: '',
            part_number_external: '',
            description: '',
            total_qty: '',
            balance: '',
            satuan: '',
            sourcing: '',
            qty_actual_receipt: '',
            tgl_receipt: '',
            unique_code: '',
            label_part : '',
            label_kemasan: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        setIsLeaderDisabled(false);
        setSearchDisabled(false);
        getData(keyword, page, sort);
      })
    }
  };

  // Fungsi untuk menutup modal leader
  const handleCancelLeaderModal = () => {
    setModalLeader(false);
    setIsLeaderDisabled(false);
    setIsDisabled(false);
    setSearchDisabled(false);
    setItem({
      ...item,
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      description: '',
      total_qty: '',
      balance: '',
      satuan: '',
      sourcing: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part : '',
      label_kemasan: '',
    });
  };

  
  const baseColumns = [
    {
      // title: (
      //   <Checkbox
      //     indeterminate={
      //       selectedKeys.length > 0 && selectedKeys.length < selectableKeys.length
      //     }
      //     onChange={onCheckAllChange}
      //     checked={selectedKeys.length === selectableKeys.length && selectableKeys.length > 0}
      //   />
      // ),
      title: '',
      dataIndex: 'no',
      key: 'no',
      align:'center',
      width:50,
      render: (text, record) => (
        <Checkbox
          value={record.part_number}
          disabled={record.is_submitted !== 1}
          checked={selectedKeys.some((key) => key.part_number === record.part_number)}
          onChange={() => onCheckChange(record.part_number)}
        />
      ),
    },
    {
      title: 'INVOICE',
      dataIndex: 'number',
      key: 'number',
      align:'center',
      width:200,
    },
    {
      title: 'PO NUMBER',
      dataIndex: 'po_number',
      key: 'po_number',
      align:'center',
      width:200,
    },
    {
      title: 'PART NUMBER',
      dataIndex: 'part_number',
      key: 'part_number',
      align:'center',
      width:200,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      align:'center',
    },
    {
      title: 'SNP',
      align:'center',
      width:50,
      // dataIndex: 'snp',
      // key: 'snp',
      render: (text,record,index) => {
        return (formattedNumber(record.snp));
      }
    },
    {
      title: 'PACKING LIST',
      align:'center',
      children: [
        {
          title: 'PACKAGES',
          align: 'center',
          children:[
            {
              dataIndex: 'packages',
              key: 'packages',
              align:'center',
              width:50,
            },
            {
              dataIndex: 'uom',
              key: 'uom',
              align:'center',
              width:50,
            },
          ]
          // render: (text, record, index) => {
          //   return (
          //     <table
          //       border={0}
          //       style={{
          //         padding: '0',
          //         borderCollapse: 'collapse',
          //         width: '100px',
          //         margin: '0 auto', // Center the table itself within the cell
          //       }}
          //     >
          //       <tbody>
          //         <tr>
          //           <td
          //             style={{
          //               border: 0,
          //               textAlign: 'center',
          //               paddingLeft: 20,
          //               paddingRight: 20,
          //             }}
          //           >
          //             {record.packages || 0}
          //           </td>
          //           <td
          //             style={{
          //               border: 0,
          //               textAlign: 'center',
          //               paddingLeft: 20,
          //               paddingRight: 20,
          //             }}
          //           >
          //             {record.packages_uom || 0}
          //           </td>
          //         </tr>
          //       </tbody>
          //     </table>
          //   );
          // },
        },
        {
          title:'QUANTITY',
          align:'center',
          width:50,
          // dataIndex:'quantity',
          // key:'quantity',
          render: (text,record,index) => {
            return (formattedNumber(record.quantity));
          }
        },
        {
          title:'SATUAN',
          dataIndex:'uom',
          key:'uom',
          align:'center',
          width:50,
        },
      ],
    },
    {
      title: 'ACTUAL',
      align:'center',
      children: [
        {
          title:'QUANTITY',
          align:'center',
          width:50,
          // dataIndex:'qty_actual',
          // key:'qty_actual',
          render: (text,record,index) => {
            return (formattedNumber(record.qty_actual));
          }
        },
        {
          title:'SATUAN',
          dataIndex:'uom',
          key:'uom',
          align:'center',
          width:50,
        },
        {
          title:'BALANCE',
          align:'center',
          width:50,
          // dataIndex:'balance',
          // key:'balance',
          render: (text,record,index) => {
            return (formattedNumber(record.balance));
          }
        },
      ],
    },
    {
      title : 'CHECK ITEM',
      align:'center',
      children: [
        {
          title: 'LABEL PART',
          align: 'center',
          // dataIndex: 'label_part',
          // key:'label_part',
          render: (text,record,index) => {
            return `${record.label_part}%`;
          }
        },
        {
          title: 'KEMASAN',
          align: 'center',
          // dataIndex: 'kemasan',
          // key:'kemasan',
          render: (text,record,index) => {
            return `${record.kemasan}%`;
          }
        },
      ]
    },
    {
      title : 'PIC',
      align : 'center',
      children : [
        {
          title : 'RECEIVE BY',
          align : 'center',
          dataIndex:'created_by',
          key:'created_by',
        }
      ]
    },
    {
      title : 'LEADER',
      align : 'center',
      dataIndex:'approved_by',
      key:'approved_by',
      // children : [
      //   {
      //     title : 'CHECK',
      //     align : 'center',
      //     render: (text,record,index) => {
      //       if(record.approved_by === username && record.is_submitted === 1){
      //         return (
      //           <Button type="primary"  onClick={() => {
      //             // Your click event logic here
      //             handleSubmitted(record.part_number,record.number);
      //           }}>Submit</Button>
      //         );
      //       }
      //     }
      //   }
      // ]
    },
    {
      title : 'ACTION',
      align : 'center',
      render: (text,record,index) => {
        return (
          <Fragment>
            <Dropdown className="pointer" overlay={
                <Menu>
                    <Menu.Item key="1" onClick={() => showScanModal(record)}  disabled={record.balance === 0 || isDisabled}>
                    Receipt Manual
                    </Menu.Item>
                    {(record.approved_by === username || role_name == 'Admin') && record.is_submitted === 1 && (
                      <Menu.Item key="2"  onClick={() => showLeaderModal()}  disabled={isDisabled}>
                        Leader Check
                          {
                            modalLeader && (
                              <LeaderCheck record={record} visible={modalLeader} onOk={handleOkLeaderModal} onCancel={handleCancelLeaderModal} disabled={isLeaderDisabled} item={item}
                              setItem={setItem} />
                            )
                          }
                      </Menu.Item>
                    )}
                    <Menu.Item key="3" onClick={() => {
                          handleSubmitted(record.part_number,record.number);
                        }} disabled={record.is_submitted !== 1 ? true : false}>
                        Printed
                    </Menu.Item>
                </Menu>
            } trigger={['click']}>
                <span className="pointer" onClick={e => e.preventDefault()}>
                    <MoreOutlined />
                </span>
            </Dropdown>
            {/* modal receipt manual */}
            <Modal
          title="Receipt Manual"
          visible={isScanModalVisible}
          onOk={handleOkScanModal}
          onCancel={handleCancelScanModal}
          width={800}
          okButtonProps={{ disabled: isOkDisabled }}  
        >
          <Form 
            {...layout}
            name="control-hooks">
            <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Part Number"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.part_number} disabled />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                      label="Description"
                      // validateStatus={error && error.part_number_internal ? 'error' : false }
                      // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                  >
                      <Input value={item.description} disabled />
                  </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Total Qty"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={formattedNumber(item.total_qty)} disabled />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Satuan"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.satuan} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Qty Actual Receipt"
                    required
                    validateStatus={error && error.qty_actual_receipt ? 'error' : false }
                    help={error && error.qty_actual_receipt ? error.qty_actual_receipt : false }
                >
                    <Input value={item.qty_actual_receipt} onChange={(e) => handleChangeScanModal('qty_actual_receipt', e.target.value)}/>
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Receipt Date"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={date} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 5]} className="align-left">
              <Col md={12} lg={12} xs={12} style={{marginRight:10}}>
                <Form.Item
                  label="Unique Code"
                  style={{ textAlign: 'left' }}
                >
                  <Input 
                    value={item.unique_code} 
                    onChange={(e) => handleChangeScanModal('unique_code', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button onClick={showUniqueCodeModal} disabled={isDisabled}>
                  <QrcodeOutlined />
                </Button>
                <Modal
                  title="Scan QR Unique Code"
                  visible={isUniqueCodeModalVisible}
                  onCancel={handleCancelQrUniqueCode}
                  footer={null}
                >
                  {success ? (
                    <QrReader
                      delay={100}
                      onError={handleError}
                      onScan={handleScanUniqueCode}
                      className="qrcode"
                      showViewFinder={false}
                      style={{ width: '200px', height: '200px' }}
                    />
                  ) : (
                    <div className="qrcode-fail">
                      <p>{messageQr}</p>
                      <p>Retry</p>
                      <Button type="link" size="large" onClick={handleRetry}>
                        <RedoOutlined />
                      </Button>
                    </div>
                  )}
                </Modal>
              </Col>
              {/* <Col span={2}>
              <Button onClick={showBarcodeModal} disabled={isDisabled}>
                <BarcodeOutlined />
              </Button>
              <Modal
                title="Scan Barcode Unique Code"
                visible={modalBarcode}
                onCancel={handleCancelBarcodeModal}
                footer={null}
              >
                {success ? (
                  <div>
                    <BarcodeScanner show={modalBarcode} setShow={setSuccess} setValue={handleScanBarCode} />
                  </div>
                ) : (
                  <div className="qrcode-fail">
                    <p>{messageQr}</p>
                    <p>Retry</p>
                    <Button type="link" size="large" onClick={handleRetry}>
                      <RedoOutlined />
                    </Button>
                  </div>
                )}
              </Modal>
              </Col> */}
            </Row>
            <Row type="flex" gutter={[10, 20]} justify="space-around">
              <Col xs={24} lg={12} md={12}>
                <Form.Item label="Label Part"
                  required
                >
                  {item.label_part ? (
                    <Badge count={item.label_part == '1' ? 'OK' : 'NG'} style={{
                      backgroundColor: item.label_part == '1' ? '#52c41a' : 'red',
                    }} />
                  ) :  (
                  <>
                  <Button
                      type="primary"
                      style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', marginRight: '20px' }}onClick={() => {
                        // Your click event logic here
                        handleChangeScanModal('label_part', '1');
                      }}>
                      √
                  </Button>
                  <Button type="primary" danger onClick={() => {
                      // Your click event logic here
                      handleChangeScanModal('label_part', '0');
                    }}>
                    X
                  </Button>
                  </>
                  )}
                </Form.Item>
              </Col>          
              <Col md={12} lg={12} xs={24}>
                <Form.Item label="Kemasan"
                  required
                >
                {item.label_kemasan ? (
                    <Badge count={item.label_kemasan == '1' ? 'OK' : 'NG'} style={{
                      backgroundColor: item.label_kemasan == '1' ? '#52c41a' : 'red',
                    }} />
                  ) :  (
                  <>
                  <Button
                      type="primary"
                      style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', marginRight: '20px' }}onClick={() => {
                        // Your click event logic here
                        handleChangeScanModal('label_kemasan', '1');
                      }}>
                      √
                  </Button>
                  <Button type="primary" danger onClick={() => {
                      // Your click event logic here
                      handleChangeScanModal('label_kemasan', '0');
                    }}>
                    X
                  </Button>
                  </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
            </Modal>
            {/* modal update label part dan kemasan */}
            <Modal
          title="Update Label Part and Kemasan"
          visible={isPartModalVisible}
          onOk={handleOkPartModal}
          // onCancel={handleCancelPartModal}
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={800}  
        >
          <Form 
            {...layout}
            name="control-hooks">
             <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Part Number"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.part_number} disabled />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                      label="Description"
                      // validateStatus={error && error.part_number_internal ? 'error' : false }
                      // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                  >
                      <Input value={item.description} disabled />
                  </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Total Qty"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={formattedNumber(item.total_qty)} disabled />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Satuan"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={item.satuan} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Qty Actual Receipt"
                    required
                    validateStatus={error && error.qty_actual_receipt ? 'error' : false }
                    help={error && error.qty_actual_receipt ? error.qty_actual_receipt : false }
                >
                    <Input value={item.qty_actual_receipt} onChange={(e) => handleChangeScanModal('qty_actual_receipt', e.target.value)} disabled/>
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xs={24}>
                <Form.Item
                    label="Receipt Date"
                    // validateStatus={error && error.part_number_internal ? 'error' : false }
                    // help={error && error.part_number_internal ? error.part_number_internal[0] : false }
                >
                    <Input value={date} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 5]} className="align-left">
              <Col md={12} lg={12} xs={12} style={{marginRight:10}}>
                <Form.Item
                  label="Unique Code"
                  style={{ textAlign: 'left' }}
                >
                  <Input 
                    value={item.unique_code} 
                    onChange={(e) => handleChangeScanModal('unique_code', e.target.value)} disabled
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button onClick={showUniqueCodeModal} disabled>
                  <QrcodeOutlined />
                </Button>
                <Modal
                  title="Scan QR Unique Code"
                  visible={isUniqueCodeModalVisible}
                  onCancel={handleCancelQrUniqueCode}
                  footer={null}
                >
                  {success ? (
                    <QrReader
                      delay={100}
                      onError={handleError}
                      onScan={handleScanUniqueCode}
                      className="qrcode"
                      showViewFinder={false}
                    />
                  ) : (
                    <div className="qrcode-fail">
                      <p>{messageQr}</p>
                      <p>Retry</p>
                      <Button type="link" size="large" onClick={handleRetry}>
                        <RedoOutlined />
                      </Button>
                    </div>
                  )}
                </Modal>
              </Col>
              {/* <Col span={2}>
              <Button onClick={showBarcodeModal} disabled>
                <BarcodeOutlined />
              </Button>
              <Modal
                title="Scan Barcode Unique Code"
                visible={modalBarcode}
                onCancel={handleCancelBarcodeModal}
                footer={null}
              >
                {success ? (
                  <div>
                    <BarcodeScanner show={modalBarcode} setShow={setSuccess} setValue={handleScanBarCode} />
                  </div>
                ) : (
                  <div className="qrcode-fail">
                    <p>{messageQr}</p>
                    <p>Retry</p>
                    <Button type="link" size="large" onClick={handleRetry}>
                      <RedoOutlined />
                    </Button>
                  </div>
                )}
              </Modal>
              </Col> */}
            </Row>
            <Row type="flex" gutter={[10, 5]} justify="space-around">
              <Col xs={24} lg={12} md={12}>
                <Form.Item label="Label Part"
                  required
                >
                  {item.label_part ? (
                    <Badge count={item.label_part == '1' ? 'OK' : 'NG'} style={{
                      backgroundColor: item.label_part == '1' ? '#52c41a' : 'red',
                    }} />
                  ) :  (
                  <>
                  <Button
                      type="primary"
                      style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', marginRight: '20px' }}onClick={() => {
                        // Your click event logic here
                        handleChangeScanModal('label_part', '1');
                      }}>
                      √
                  </Button>
                  <Button type="primary" danger onClick={() => {
                      // Your click event logic here
                      handleChangeScanModal('label_part', '0');
                    }}>
                    X
                  </Button>
                  </>
                  )}
                </Form.Item>
              </Col>          
              <Col md={12} lg={12} xs={24}>
                <Form.Item label="Kemasan"
                  required
                >
                {item.label_kemasan ? (
                    <Badge count={item.label_kemasan == '1' ? 'OK' : 'NG'} style={{
                      backgroundColor: item.label_kemasan == '1' ? '#52c41a' : 'red',
                    }} />
                  ) :  (
                  <>
                  <Button
                      type="primary"
                      style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', marginRight: '20px' }}onClick={() => {
                        // Your click event logic here
                        handleChangeScanModal('label_kemasan', '1');
                      }}>
                      √
                  </Button>
                  <Button type="primary" danger onClick={() => {
                      // Your click event logic here
                      handleChangeScanModal('label_kemasan', '0');
                    }}>
                    X
                  </Button>
                  </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
            </Modal>
            {/* modal untuk revisi item by leader */}
          </Fragment>
        // <>
        // <Button type="primary" onClick={() => showScanModal(record)} disabled={isDisabled}>
        //   Scan Manual
        // </Button>
        
        // </>
        );
      }
    },
  ];
  
  
  if (!sessionStorage.getItem('token')) {
    return <Redirect to="/login" />
  }
  return (
    <Fragment>
        <Row>
          <Col xs={24}>
            <Card>
              <Title level={4}>Checksheet Warehouse</Title>
              <Row type="flex" justify="left">
                <Breadcrumb>
                  <Breadcrumb.Item>Checksheet Warehouse</Breadcrumb.Item>
                </Breadcrumb>
              </Row>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <Col xs={24} md={24} lg={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[12, 12]} justify="space-around">
                <Col xs={24} md={24} lg={12}>
                    <Row>
                      <Col md={24} lg={8} className="align-right">
                          <Text strong={true} className="text-14">
                            Hari & Tanggal 
                          </Text>
                      </Col>
                      <Col>
                        <Text strong={true} className="text-14">
                          &nbsp; : &nbsp;
                        </Text>
                      </Col>
                      <Col md={24} lg={12} xs={24}>
                          <Text strong={true}>
                            {formattedDate}
                          </Text>
                      </Col>
                    </Row>
                    <Row style={{marginTop:20}}>
                      <Col md={8} lg={8} className="align-right">
                         <Text strong={true} className="text-14">
                          Invoice
                         </Text>
                      </Col>
                      <Col>
                        <Text strong={true} className="text-14">
                          &nbsp; : &nbsp;
                         </Text>
                      </Col>
                      <Col md={24} lg={12} xs={24}>
                          {/* <Search
                            placeholder="No Invoice ..."
                            defaultValue={keyword}
                            onSearch={(value) => handleSearch(value)}
                            disabled={searchDisabled}
                          /> */}
                          <Form.Item>
                          <Select labelInValue
                              value={{ key: invoices.inv_id, label: invoices.inv_number }} onChange={(value) => handleChangeSelect('inv', value)} onFocus={() => getInvoices('')} showSearch onSearch={(value) => getInvoices(value)} filterOption={false} disabled={searchDisabled}>
                              {
                                  dataInvoices && dataInvoices.map(refer => {
                                      return <Option key={refer._id} value={refer._id}>{refer.number}</Option>
                                  })
                              }
                          </Select>
                          </Form.Item>
                      </Col>
                    </Row>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  {/* First Row */}
                  {/* <Row justify="end">
                    <Col>
                      <Text strong={true} className="text-18">
                        Penulisan : &nbsp;( √ = OK ), ( X = NG ), &nbsp;( - = Tidak dipakai )
                      </Text>
                    </Col>
                  </Row> */}

                  {/* Second Row - Input and Button below the first row */}
                  <Row justify="end"> {/* Use margin for spacing and justify for alignment */}
                    <Col md={24} lg={12} xs={24}>
                      <Row type="flex" gutter={[10, 20]} justify="space-around">
                        <Col md={20} lg={20} xs={20}>
                          <Input placeholder="Scan Material" value={scanMaterial}
                          disabled={isDisabled}  onChange={handleChangeScanMaterial}
                          onKeyPress={handlePressScanMaterial} ref={inputRef} />
                        </Col>
                        <Col md={4} lg={4} xs={4}>
                          <Button onClick={showModal} disabled={isDisabled}>
                            <CameraOutlined />
                          </Button>
                          <Modal
                            title="Scan QR"
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            footer={null} // This removes the OK and Cancel buttons
                          >
                            {
                              success ? (
                                  <QrReader
                                      delay={100}
                                      onError={handleError}
                                      onScan={handleScan}
                                      className="qrcode"
                                      showViewFinder={false}
                                      style={{ width: '200px', height: '200px' }}
                                      />
                              ) : (
                                  
                                  <div className="qrcode-fail">
                                      <p>{messageQr}</p>
                                      <p>Retry</p>
                                      <Button type="link" size="large" onClick={handleRetry}><RedoOutlined /></Button>
                                  </div>
                                  
                              )
                            }
                          </Modal>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Table
                    className="custom-table"
                    columns={baseColumns}
                    dataSource={checkSheets.data}
                    rowKey={(data) => data._id} // Make sure this is an array
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    bordered
                    scroll={{ x: 500 }}
                  />
                </Col>  
              </Row>
              <Row>
                <Col xs={24} style={{ textAlign: 'right' }}>
                  {selectableKeys.length > 0 && (
                    <Button loading={loading} type="primary" onClick={handleSubmittedAll} style={{marginRight:10}}>
                        Submit All
                    </Button>
                  )}
                  {checkSheets.total > 0 && checkSheets.total === checkSheets.total_finished && (
                    <Button loading={loading} type="primary" onClick={handlePrinted}>
                        Print CheckSheet
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Fragment>
  )
}

export default ChecksheetWarehouse