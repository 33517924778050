import {
  Card,
  Col,
  Dropdown,
  Input,
  Badge,
  Menu,
  Row,
  Table,
  Tag,
  message,
} from 'antd';
import { PrinterOutlined, MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState, Fragment } from 'react';
import Axios from 'axios';
import moment from 'moment'
import {url} from '../../../config/global'

function HistoryScan({ id, rows, part_number, no_invoice, po_number }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(5);
    const [keyword, setKeyword] = useState('');
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend',
    });
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);


    const handleTableChange = (pagination, filters, sorter) => {
        console.log(sorter, "sort");
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field || 'created_at',
            order: sorter.order || 'descend',
        });
    };

    useEffect(() => {
        getDetails();
        return () => {
            setData([]);
        };
    }, [page, perpage, sort, keyword, no_invoice, po_number]);

    const getDetails = () => {
        setLoading(true);
        Axios.get(`${url}/invoice-history-scan`, {
            params: {
                keyword,
                no_invoice:no_invoice,
                po_number:po_number,
                part_number:part_number,
                perpage: perpage,
                page,
                sort: sort.sort,
                order: sort.order,
                columns: ['part_number', 'description'],
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePrint = (id) => {
        setLoading(true);
        Axios.get(`/generate-label/history/${id}/print`, {
            responseType: 'blob',
        })
            .then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                getDetails();
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    message.error('Server error');
                } else {
                    if (error.response) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error('Error:', error.message.message);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleReprint = async (record) => {
      setLoading(true);
      try {
          const { data } = await Axios.get(`${url}/warehouse-checksheets/reprint-single/${record._id}`, {
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
              responseType: 'blob', // Move responseType here
          });
  
          // Check if data exists
          if (data) {
              const file = new Blob([data], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          } else {
              message.error('No data returned from server.');
          }
      } catch (error) {
          if (error.response) {
              if (error.response.status === 500) {
                  message.error('Server error');
              } else {
                  const reader = new FileReader();
                  reader.onload = function () {
                      try {
                          const errorMessage = JSON.parse(reader.result);
                          message.error(errorMessage.message || 'Error processing the request.');
                      } catch (err) {
                          message.error('Error reading the error message.');
                      }
                  };
                  reader.readAsText(error.response.data);
              }
          } else {
              message.error(`Error: ${error.message}`);
          }
      } finally {
          setLoading(false);
          getDetails();
      }
    };
  

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const pagination = {
        pageSize: 5,
        current: page,
        total: total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      };

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };

    const columns = [
        {
            title: 'NO',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            width:100,
            fixed: 'left',
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            }
        },
        {
          title: 'PART NUMBER EXTERNAL',
          dataIndex: 'part_number_external',
          key: 'part_number_external',
          align:'center',
          width:200,
        },
        {
          title: 'QR CODE',
          dataIndex: 'qr_code',
          key: 'qr_code',
          align:'center',
        },
        {
          title: 'QTY',
          align:'center',
          width:50,
          // dataIndex: 'snp',
          // key: 'snp',
          render: (text,record,index) => {
            return (formattedNumber(record.qty));
          }
        },
        {
          title: 'UNIQUE CODE',
          align:'center',
          dataIndex: 'unique_code',
          key: 'unique_code',
        },
        {
          title: 'GENERATE NUMBER',
          align:'center',
          dataIndex: 'gen_number',
          key: 'gen_number',
        },
        {
          title: 'SEQUENCE',
          align:'center',
          dataIndex: 'sequence',
          key: 'sequence',
        },
        {
          title : 'CHECK ITEM',
          align:'center',
          children: [
            {
              title: 'LABEL PART',
              align: 'center',
              // dataIndex: 'label_part',
              // key:'label_part',
              render: (text,record,index) => {
                return <Badge count={record.label_part == '1' ? 'OK' : 'NG'} style={{
                    backgroundColor: record.label_part == '1' ? '#52c41a' : 'red',
                }} />;
              }
            },
            {
              title: 'KEMASAN',
              align: 'center',
              // dataIndex: 'kemasan',
              // key:'kemasan',
              render: (text,record,index) => {
                return <Badge count={record.label_kemasan == '1' ? 'OK' : 'NG'} style={{
                    backgroundColor: record.label_kemasan == '1' ? '#52c41a' : 'red',
                }} />;
              }
            },
          ]
        },
        {
          title : 'PIC',
          align : 'center',
          children : [
            {
              title : 'RECEIVE BY',
              align : 'center',
              dataIndex:'created_by',
              key:'created_by',
            }
          ]
        },
        {
            title: 'CREATED AT',
            align:'center',
            dataIndex: 'created_at',
            key: 'created_at',
            width:200,
            render : (text,record,index) => {
                return moment(record.created_at).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
          title : 'ACTION',
          align : 'center',
          render: (text,record,index) => {
            return (
              <Fragment>
                <Dropdown className="pointer" overlay={
                    <Menu>
                        <Menu.Item key="1" onClick={() => handleReprint(record)}  disabled={record.status == 0}>
                          Reprint
                        </Menu.Item>
                    </Menu>
                } trigger={['click']}>
                    <span className="pointer" onClick={e => e.preventDefault()}>
                        <MoreOutlined />
                    </span>
                </Dropdown>
                {/* modal untuk revisi item by leader */}
              </Fragment>
            // <>
            // <Button type="primary" onClick={() => showScanModal(record)} disabled={isDisabled}>
            //   Scan Manual
            // </Button>
            
            // </>
            );
          }
        },
      ];
    return (
        <Card>
            <Row justify="end">
                <Col>
                    <Input.Search
                        allowClear="true"
                        onSearch={(value) => setKeyword(value)}
                        placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col xs={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: true }}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default HistoryScan;
